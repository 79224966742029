import "./src/styles/global.css"
import "./src/styles/fonts.css";

  export const onInitialClientRender = () => {
    const setVhProperty = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
    
      setVhProperty();
      window.addEventListener('orientationchange', setVhProperty);    
  }